import styled from "styled-components";

export const MultiStepFormWrapper = styled.div`
	margin: 0 auto;
	${({ hasContentAbove, hasContentBelow }) =>
		!hasContentAbove && !hasContentBelow && `height:100%;`}
	${({ hasContentAbove }) => hasContentAbove && `margin-top: 1rem;`}
    ${({ hasContentBelow }) => hasContentBelow && `margin-bottom: 2rem;`}
    position:relative;

	.multi-step-form-wrapper {
		display: flex;
		flex-direction: column;
		height: 100%;
	}

	.input-form {
		max-width: 400px;
		margin-top: 24px;
		margin-bottom: 24px;

		& input {
			margin-right: 0;
			width: 98%;
		}

		.start-application-button {
			width: 98%;
			margin-bottom: 8px;
		}
	}
	.select-course-desc {
		margin-top: 1rem;
		& .select-course-text {
			margin-top: 1rem;
			font-weight: 500;
		}
	}
	.option-container {
		display: flex;
		min-width: 280px;
		height: 100%;
		flex-direction: column;
		justify-content: space-between;
	}
	.option-container input {
		-webkit-appearance: none;
		-moz-appearance: none;
		appearance: none;
	}

	.option-container label {
		cursor: pointer;
	}
	.option-content-wrapper {
		display: flex;
		flex-wrap: wrap;
		gap: 8px;
		margin-top: 32px;
		margin-bottom: 16px;
	}
	.option-content {
		flex: auto;
		max-width: 120px;
		min-width: 100px;
		height: 53px;
		position: relative;
		align-items: center;
		justify-content: center;
		display: flex;
		border-radius: 4px;
		background: none;
		border: ${(props) =>
			props.hasDarkBackground
				? "1px solid var(--secondary-purple-100)"
				: "1px solid var(--secondary-purple-300)"};
		padding: 0 10px;
		text-align: center;
		transition: all 200ms ease-in;
		color: ${(props) =>
			props.hasDarkBackground
				? "var(--primary-neutral-white)"
				: "var(--primary-neutral-nightshade-800)"};
		&:hover {
			background: var(--secondary-purple-100);
			border: 1px solid var(--secondary-purple-100);
			color: var(--primary-neutral-nightshade-800);
		}
	}

	.explore-tag {
		display: flex;
		flex-direction: column;
		gap: 16px;
		margin-top: 1rem;
		width: fit-content;
	}

	.multi-step-form-links {
		display: flex;
		flex-direction: column;
		gap: 12px;
		margin-top: 1rem;
		width: fit-content;
	}

	.input-button {
		position: absolute;
		height: 100%;
		width: 100%;
		left: 0;
		right: 0;
		bottom: 0;
		top: 0;
		cursor: pointer;
	}
`;
