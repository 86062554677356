import React, { useState, useEffect, useRef } from "react";
import { handleCTAClick } from "@website-builder/utilities/utils/DesignUtils.js";
import { addToDataLayer } from "@website-builder/utilities/utils/utils.js";
import { BUTTON_INTERACTION } from "@website-builder/utilities/constants/click-events.js";
import { GetReferrer } from "@website-builder/utilities/utils/GetReferrer.js";
import { validateEmail } from "@website-builder/utilities/utils/validateEmail.js";
import { MULTI_STEP } from "@website-builder/utilities/constants/multi-step.js";
import { MultiStepFormWrapper } from "./style";
import { Button, Input, Link, Text } from "@website-builder/ui/shared/elements";

const MultiStepForm = ({
	multiStepForm,
	headingColor,
	textColor,
	hasDarkBackground,
	hasContentAbove,
	hasContentBelow,
	context,
	pageSection,
}) => {
	const {
		heading: multiStepFormHeading = "",
		description: multiStepFormDescription = "",
		appPreText = "",
		appSubText = "",
		programLabel = "",
		programs = {},
		showExploreTag = false,
		linkCTAs = [],
	} = multiStepForm || {};

	const optionsRef = useRef(null);
	const [email, setEmail] = useState("");
	const [error, setError] = useState({ email: "" });
	const [showErrors, setShowErrors] = useState(false);
	const [bannerSelectOptions, setBannerSelectOptions] = useState(programs);
	const [bannerStepCounter, setBannerStepCounter] = useState(1);
	const [courseCategory, setCourseCategory] = useState(null);
	const [courseValue, setCourseValue] = useState(null);
	const getSelectedOption = () =>
		document.querySelector(
			`input[name=${bannerSelectOptions[0].type || MULTI_STEP.COURSE}]:checked`,
		);
	const resetState = () => {
		setBannerSelectOptions(programs);
		setBannerStepCounter(1);
		setCourseValue(null);
		setShowErrors(false);
	};
	const selectCourseDesc = (
		<div className="select-course-desc">
			<Text variant="paragraph_L">{multiStepFormDescription}</Text>
			<Text className="select-course-text" variant="paragraph_L_bold">
				{programLabel}
			</Text>
		</div>
	);
	const updateCourseSelected = () => {
		const checkedOption = getSelectedOption();
		const selectedValue = checkedOption ? checkedOption.value : null;
		const bannerOption = bannerSelectOptions.find(
			(option) => option.name === selectedValue,
		);
		if (bannerOption?.courses && bannerOption.courses.length) {
			const step2Elems = document.getElementsByName(
				bannerSelectOptions[0].type,
			);
			step2Elems.forEach((elem) => {
				elem.checked = false;
			});
			setBannerSelectOptions(bannerOption.courses);
		}
		if (bannerOption?.courses?.length || bannerStepCounter === 2) {
			setBannerStepCounter(bannerStepCounter + 1);
		} else {
			setBannerStepCounter(bannerStepCounter + 2);
		}
		//Trigger Analytics Events
		const [referral, referral2] = GetReferrer();
		if (selectedValue && bannerOption?.type === MULTI_STEP.PROGRAM) {
			// SEGMENT PROGRAM SELECTED
			setCourseCategory(selectedValue);
			addToDataLayer({
				event: "course_category_cta_click",
				course_category: selectedValue,
				referral,
				referral2: referral2,
			});
		} else {
			// SEGMENT COURSE SELECTED
			const courseSelected = courseValue?.courses.filter(
				(course) => course.course_name === selectedValue,
			)[0];
			addToDataLayer({
				event: "course_vertical_cta_click",
				course_slug: courseSelected?.course_slug,
				course_id: courseSelected?.workshop_id,
				course_category: courseCategory,
				referral,
				referral2: referral2,
			});
		}
	};
	const updateDesignOfSelectedBox = (e) => {
		let selectedCourse = bannerSelectOptions.filter(
			(item) =>
				item?.name === e?.target?.value ||
				item?.course_name === e?.target?.value,
		);
		setCourseValue(selectedCourse && selectedCourse[0]);
		const radioElems = document.getElementsByName(
			bannerSelectOptions[0].type || MULTI_STEP.COURSE,
		);

		updateCourseSelected();
	};
	const startApplication = () => {
		let hasErrorOccured = false;
		setShowErrors(true);
		let emailValidation = validateEmail(email);
		if (emailValidation === true) {
			setError({ ...error, email: "" });
			hasErrorOccured = false;
		} else {
			setError({ ...error, email: emailValidation });
			hasErrorOccured = true;
		}
		if (hasErrorOccured) {
			return;
		}

		const cta = {
			event: BUTTON_INTERACTION.APPLICATION_START,
			link: {
				target: "_self",
			},
		};
		context["workshop_id"] = courseValue?.workshop_id || "";
		context["typeformLink"] =
			courseValue && courseValue.link ? courseValue.link : "";
		context["dripTagPrefix"] = courseValue?.dripTagPrefix || "";
		const apiData = {
			context,
			params: {
				email,
			},
		};

		const [referral, referral2] = GetReferrer();
		const eventData = {
			page_section: pageSection,
			course_slug: context?.slug,
			email,
			referral,
			referral2,
			products: JSON.stringify([{ product_id: context["workshop_id"] }]),
		};

		handleCTAClick(cta, eventData, apiData);
	};

	useEffect(() => {
		/* 
            What and why of this block:
            * tab and shift+tab are used to navigate between radio groups
            * left and right arrow keys are used to navigate between individual radio buttons in a group
            * when we press left or right, that particular radio gets checked automatically
            * focus cannot be moved without checking a radio by default
            * try navigation through radio buttons here to see behavior: https://www.w3schools.com/tags/tryit.asp?filename=tryhtml5_input_type_radio
            * This function applies a eventListener to the radio group only, to override default tab, shift+tab, left/right arrow and enter behavior
        */
		const redefineRadioKeyboardBehavior = (event) => {
			if (event.key === "ArrowLeft" || event.key === "ArrowRight") {
				event.preventDefault();
			} else if (event.key === "Tab") {
				const currentElement = event.target;
				if (event.shiftKey) {
					const previousRadio =
						currentElement?.parentElement?.previousElementSibling?.querySelector(
							"input",
						);
					if (previousRadio) {
						event.preventDefault();
						previousRadio.focus();
					}
				} else {
					const nextRadio =
						currentElement?.parentElement?.nextElementSibling?.querySelector(
							"input",
						);
					if (nextRadio) {
						event.preventDefault();
						nextRadio.focus();
					}
				}
			} else if (event.key === "Enter") {
				event.target.click();
			}
		};

		if (optionsRef?.current) {
			optionsRef.current.addEventListener(
				"keydown",
				redefineRadioKeyboardBehavior,
			);
		}

		return () => {
			if (optionsRef?.current) {
				optionsRef.current.removeEventListener(
					"keydown",
					redefineRadioKeyboardBehavior,
				);
			}
		};
	}, [optionsRef]);

	return (
		<MultiStepFormWrapper
			className="multi-step-form"
			hasContentAbove={hasContentAbove}
			hasContentBelow={hasContentBelow}
		>
			<div className="multi-step-form-wrapper">
				<Text
					variant="headline_2"
					tag="h1"
					className="hero-banner-title"
					color={headingColor}
				>
					{multiStepFormHeading}
				</Text>
				{bannerStepCounter === 3 ? (
					<Text
						variant="paragraph_L"
						style={{ marginTop: "1rem" }}
						color={textColor}
					>
						{appPreText}
					</Text>
				) : (
					selectCourseDesc
				)}
				{bannerStepCounter === 3 ? (
					<>
						<div className="input-form">
							<Input
								name="email"
								type="email"
								placeholder="Enter your email"
								className="email-input"
								value={email}
								showErrors={showErrors}
								error={[error, setError]}
								onChange={(e) => {
									setEmail(e.target.value.trim());
								}}
								onFocus={() => {
									setError({ email: "" });
								}}
								onBlur={(e) => validateEmail(e.target.value)}
							></Input>
							<Button
								type="primary"
								buttonText="START MY APPLICATION"
								className="start-application-button"
								onClick={() => startApplication()}
							/>
							<div className="explore-tag">
								{appSubText ? (
									<Text color={textColor}>{appSubText}</Text>
								) : null}
								{showExploreTag ? (
									courseValue &&
									courseValue.course_link &&
									courseValue.course_link.link ? (
										<Link
											arrowCta
											href={courseValue?.course_link?.link}
											target={courseValue?.course_link?.target}
											linkText="Explore the track"
											onClick={(e) => {
												resetState();
												handleCTAClick(
													{
														event: BUTTON_INTERACTION.LINK_CLICK,
														link: {
															type: courseValue.course_link.type,
															target: courseValue.course_link.target || "_self",
															link: courseValue.course_link.link,
														},
													},
													{
														click_text: "Explore the track",
														redirect_to: courseValue.course_link.link,
														page_section: pageSection,
													},
													{},
													e,
												);
											}}
											darkBg={hasDarkBackground}
										/>
									) : null
								) : null}
							</div>
						</div>
					</>
				) : (
					<div className="option-container">
						<div
							className="option-content-wrapper"
							id="multistep-form-options-content"
							ref={optionsRef}
						>
							{bannerSelectOptions.map((option, index) =>
								option.component === "ButtonWR" ? (
									<div
										className="option-content"
										key={index}
										onClick={(e) => {
											handleCTAClick(
												{
													event: option.event,
													link: option.link,
												},
												{
													click_text: option.buttonText,
													redirect_to: option.link.link,
													page_section: pageSection,
												},
												{},
												e,
											);
										}}
									>
										<input
											className="input-button"
											type="radio"
											aria-label={option.buttonText}
											id={option.buttonText}
											name={"cta"}
											value={option.buttonText}
										/>
										<label htmlFor={option.buttonText}>
											<Text variant="paragraph_S_bold">
												{option.buttonText}
											</Text>
										</label>
									</div>
								) : (
									<div className="option-content" key={index}>
										<input
											className="input-button"
											type="radio"
											aria-label={option.course_name || option.name}
											id={option.course_name || option.name}
											name={option.type || MULTI_STEP.COURSE}
											value={option.course_name || option.name}
											onChange={(e) => updateDesignOfSelectedBox(e)}
										/>
										<label htmlFor={option.course_name || option.name}>
											<Text variant="paragraph_S_bold">
												{option.course_name || option.name}
											</Text>
										</label>
									</div>
								),
							)}
						</div>
						{linkCTAs?.length > 0 ? (
							<div className="multi-step-form-links">
								{linkCTAs.map((cta, index) => {
									return (
										<Link
											key={index}
											href={cta.link.link}
											target={cta.link.target}
											arrowCta
											linkText={cta.linkText}
											onClick={(e) => {
												resetState();
												e.preventDefault();
												handleCTAClick(
													{
														event: cta.event || BUTTON_INTERACTION.LINK_CLICK,
														link: cta.link,
													},
													{
														click_text: cta.linkText,
														redirect_to: cta.link.link,
														page_section: pageSection,
													},
													{ context },
												);
											}}
											darkBg={hasDarkBackground}
										/>
									);
								})}
							</div>
						) : (
							""
						)}
					</div>
				)}
			</div>
		</MultiStepFormWrapper>
	);
};
export default MultiStepForm;
